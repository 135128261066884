import { FormWrapper } from '../../../../../theme/default/base'
import { HtmlInput } from '../../form-inputs/Html'
import { StandardInput } from '../../form-inputs/Standard'

export const PricingTiersForm = ({
  title = 'Find a plan that is right for your business',
  sub_title = '',
  contact_cta_text = '',
  contact_cta_url = '',
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <HtmlInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={sub_title}
        name="sub_title"
        label="Sub Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={contact_cta_text}
        name="contact_cta_text"
        label="Contact CTA Text"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={contact_cta_url}
        name="contact_cta_url"
        label="Contact CTA URL"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}
