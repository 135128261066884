import React from 'react'
import { inject, observer } from 'mobx-react'
import { PAGEURL } from '/constants'
import { sortSections } from '/components/utils'

import {
  PricingHeader,
  PricingSection,
  PgTierRow,
  StickyPricingGrid,
  PricingGridTable
} from '/components/theme/plans/Plan'

import {
  VisbilityContainer,
} from '/components/home/Animations'

export const formatUpgradeLink = (planCount, p, f) => {
  if (p > planCount) {
    return `${PAGEURL.SUBSCRIBE}?plan=${p}&freq=${f}`
  } else {
    return null
  }
}

export const PricingGrid = inject('store')(
  observer(({ store, sections }) => {
    const plans = store.siteData.get('plans')
    if (!sections || sections.length === 0) {
      return null
    }

    const plan_tiers = []
    plans.forEach((plan, i) => {
      let text = null
      let url = null
      if (store.isAuthenticated()) {
        url = formatUpgradeLink(plans.length, plan, '')
        if (url) {
          text = 'Upgrade'
        }
      } else {
        url = `${PAGEURL.SIGNUP}?plan=${plan}&freq=${''}`
        text = 'Subscribe'
      }
      plan_tiers.push(
        <PricingHeader
          key={i}
          title={plan.name}
          plan={plan.order}
          frequency={plan.interval}
          url={url}
        />
      )
    })
    return (
      <div>
        <StickyPricingGrid>
          <thead>
            <PgTierRow>
              <td></td>
              {plan_tiers}
            </PgTierRow>
          </thead>
        </StickyPricingGrid>
        <PricingGridTable>
          <tbody>
            {sections.map((section, i) => (
              <PricingSection key={i} section={section} plans={plans} />
            ))}
          </tbody>
        </PricingGridTable>
      </div>
    )
  })
)

export const PricingCompare = inject('store')(
  observer(({ store, products = []}) => {
    // TODO: this needs to get moved into a utility class. I copied it from
    // MarketplaceDisplay
    function filterByTag(product_slug = '') {
      const sections = {}
      products.forEach((product) => {
        if (!product.tags) {
          return
        }
        product.tags.forEach((tag) => {
          let s = {}
          if (sections[tag.slug]) {
            s = sections[tag.slug]
          } else {
            s.id = tag.id
            s.name = tag.name
            s.slug = tag.slug
            s.order = []
            s.tiles = []
            s.products = []
            sections[tag.slug] = s
          }
          if (product_slug) {
            if (product_slug !== product.slug) {
              s.tiles.push(product.slug)
              s.products.push(product)
            }
          } else {
            s.tiles.push(product.slug)
            s.products.push(product)
            if (product.arrangement) {
              s.order.push({
                product: product.slug,
                order: product.ordering || 0,
                tier: product.access_tier
              })
            }
          }
        })
      })
      const r = []
      for (const k in sections) {
        r.push(sections[k])
      }
      r.forEach((p) => {
        p.products.sort((a, b) => b.plans.length - a.plans.length)
      })
      return r.sort((a, b) => a.id - b.id)
    }

    const sections = sortSections(filterByTag())
    if (!sections || sections.length === 0) {
      return null
    }

    return (
      <div className="pricing-grid-block">
        <div className="content">
          <VisbilityContainer>
            <div className="content-wrapper description">
              <h2 id="compare-plans">See What's Included</h2>
              <PricingGrid products={products} sections={sections} />
            </div>
          </VisbilityContainer>
        </div>
      </div>
    )
  })
)

