import React, { useState } from 'react'
import useModal from '../../../../../../hooks/useModal'
import {
  ComponentFormRender,
  COMPONENT_LOOKUP
} from '../../../../../theme/default'
import { FormWrapper } from '../../../../../theme/default/base'
import { Modal } from '../../../../Modal'
import { ComponentSelection } from '../../ComponentSelection'
import { ColorPicker } from '../../form-inputs/ColorPicker'
import { StandardInput } from '../../form-inputs/Standard'
import { v4 as uuidv4 } from 'uuid'
import { CgAddR } from 'react-icons/cg'

import styled from 'styled-components'

export const SplitForm = ({
  id,
  title,
  left,
  right,
  bg_color,
  setCurrentComponent,
  currentPage,
  setCurrentPage
}) => {
  const { visible, toggle } = useModal()
  const [side, setSide] = useState('')
  return (
    <FormWrapper>
      <Modal
        visible={visible}
        toggle={toggle}
        title="Available Components"
        windowWidth={40}
      >
        <ComponentSelection
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setCurrentComponent={setCurrentComponent}
          toggle={toggle}
          type="child"
          handleAddComponent={(componentLabel) => {
            let newComponent = {}
            for (const c in COMPONENT_LOOKUP) {
              if (COMPONENT_LOOKUP[c].label === componentLabel) {
                newComponent = JSON.parse(
                  JSON.stringify(COMPONENT_LOOKUP[c].new)
                )
                newComponent.id = uuidv4()
              }
            }
            const tempPage = { ...currentPage }
            // find index of component that needs to be updated
            let index
            tempPage.data.forEach((d, i) => {
              if (d.id === id) {
                index = i
              }
            })
            tempPage.data[index].props[side].push(newComponent)
            setCurrentPage(tempPage)
            toggle()
          }}
        />
      </Modal>
      <StandardInput
        value={title}
        name={'title'}
        label={'Title'}
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        name={'bg_color'}
        label="Background Color"
        value={bg_color}
        setCurrentComponent={setCurrentComponent}
      />
      <h2>Left</h2>
      {left?.length > 0 ? (
        left.map((leftComponent, index) => (
          <React.Fragment key={leftComponent.id}>
            <ComponentFormRender
              component={leftComponent}
              setCurrentPage={setCurrentComponent}
              extraProps={{
                sideName: 'left',
                index
              }}
            />
          </React.Fragment>
        ))
      ) : (
        <ComponentAddButton
          onClick={() => {
            setSide('left')
            toggle()
          }}
        >
          <p>Add Component</p>
          <CgAddR size={30} color="#dbdbdb" />
        </ComponentAddButton>
      )}
      <h2>Right</h2>
      {right?.length > 0 ? (
        right.map((rightComponent, index) => (
          <React.Fragment key={rightComponent.id}>
            <ComponentFormRender
              component={rightComponent}
              setCurrentPage={setCurrentComponent}
              extraProps={{
                sideName: 'right',
                index
              }}
            />
          </React.Fragment>
        ))
      ) : (
        <ComponentAddButton
          onClick={() => {
            setSide('right')
            toggle()
          }}
        >
          <p>Add Component</p>
          <CgAddR size={30} color="#dbdbdb" />
        </ComponentAddButton>
      )}
    </FormWrapper>
  )
}

const ComponentAddButton = styled.button`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin: 0 auto;
  padding: 15px;
  border: none;
  background: ${(props) => props.theme.colors.white};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: all 0.3s ease;
  p {
    color: #dbdbdb;
  }
  &:hover {
    transform: scale(1.03);
  }
`
