import React from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { APIURL } from '../../../../../../constants'
import services from '../../../../../services'
import Link from 'next/link'

const Sidebar = () => {
  const { data } = useQuery(`blogTags`, () =>
    services.get_v2(APIURL.API_GET_BLOG_TAGS)
  )

  return (
    <Wrapper>
      <h4>Categories</h4>
      <ul>
        {data?.response.map((tag) => (
          <li key={tag.div}>
            <Link href={`/blog/category/${tag.name}`}>{tag.name}</Link>
          </li>
        ))}
      </ul>
    </Wrapper>
  )
}

export default Sidebar

const Wrapper = styled.div`
  flex-basis: 20%;
  position: sticky;
  top: 0;
  margin: 0;
  padding: 5px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 990px) {
    flex-basis: 100%;
    position: relative;
    top: 0;
  }
`
