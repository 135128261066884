import { inject, observer } from 'mobx-react'
import {
  CommonPose,
  HeaderPose,
  VisbilityContainer
} from '../../../../../home/Animations'
import { ChaliceImg, interpolate } from '../../../../../theme/default/base'
import { MainCta } from '../../../../../theme/default/common'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getImageUrl, getOpacity, hasImage } from '../../utils'

export const SmallHero = inject('store')(
  observer(
    ({
      id,
      store,
      title,
      description,
      background_image,
      has_cta = true,
      text_color = 'black',
      text_shadow = false,
      isPreview,
      currentComponent = {}
    }) => {
      return (
        <Wrapper
          className="smallhero-block"
          textColor={text_color}
          textShadow={text_shadow}
        >
          <div className="content">
            <VisbilityContainer>
              <div className="content-wrapper">
                <div>
                  <HeaderPose
                    dangerouslySetInnerHTML={{
                      __html: interpolate(store.siteData, title || '')
                    }}
                  />
                </div>
                <div>
                  <CommonPose
                    className="secondary-header"
                    dangerouslySetInnerHTML={{
                      __html: interpolate(store.siteData, description || '')
                    }}
                  />
                  <MainCta hasCta={has_cta} isPreview={isPreview} />
                </div>
              </div>
            </VisbilityContainer>
          </div>
          <div className="background">
            {hasImage(background_image) && (
              <ChaliceImg
                key={id}
                src={getImageUrl(background_image)}
                opacity={getOpacity(background_image)}
              />
            )}
          </div>
        </Wrapper>
      )
    }
  )
)

SmallHero.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  backgroundUrl: PropTypes.array,
  backgroundOpacity: PropTypes.number,
  cta: PropTypes.object
}

const Wrapper = styled.div`
  color: ${(props) => props.textColor};
  text-shadow: ${(props) =>
    props.textShadow ? '2px 2px rgba(0, 0, 0, 0.3)' : null};

  h1,
  h2,
  h3,
  h4 {
    color: ${(props) => props.textColor};
    text-shadow: ${(props) =>
      props.textShadow ? '2px 2px rgba(0, 0, 0, 0.3)' : null};
  }
`
