import React from 'react'
import styled from 'styled-components'
import BlogCard from './BlogCard'

const BlogDisplay = ({ blogs }) => {
  return (
    <Wrapper>
      {blogs?.map((blog) => (
        <BlogCard
          title={blog.title || ''}
          key={blog.id}
          slug={blog.slug}
          imageUrl={blog.hero_image?.media}
          publishDate={blog.post_date || blog.publish_date || ''}
          readTime={blog.reading_time || ''}
          tags={blog.tags}
          id={blog.id}
        />
      ))}
    </Wrapper>
  )
}

export default BlogDisplay

const Wrapper = styled.div`
  flex-basis: 80%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 10px;

  @media only screen and (max-width: 990px) {
    flex-basis: 100%;
  }
`
