import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import ActionPageLoader from '../../components/loaders/ActionPageLoader'
import { APIURL } from '../../constants'
import { useRouter } from 'next/router'
import services from '../../components/services'
import { Formik, Form, Field } from 'formik'
import FormikField from '../../components/forms/FormikField'
import * as Yup from 'yup'
import LoadingButton from '../../components/common/LoadingButton'

import { Container, ProductH2 } from '../theme/default/base'
import styled from 'styled-components'

const FormWrapper = styled.div`
  padding: 20px;
  max-width: 600px;
  box-shadow: 2px 2px 16px 2px rgba(0, 0, 0, 0.15);
  margin: 20px auto;
`

const AccountSetup = inject('store')(
  observer(
    ({ store, fields = [], title = 'Finish setting up your profile' }) => {
      const [loading, setLoading] = useState(false)
      const router = useRouter()

      const handleSave = async (values) => {
        setLoading(true)
        try {
          const res = await services.post_v2(APIURL.API_ACCOUNT_SETUP, values)
          if (res.statusCode === 200) {
            const nextUrl = window.localStorage.getItem('next')
              ? window.localStorage.getItem('next')
              : '/marketplace'
            router.push(nextUrl)
          }
        } catch (e) {
          console.error(e)
          setLoading(false)
        }
      }

      const member = store.sessionData.userData
      const phoneRegExp = /^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/
      const schema = {}
      if (fields.length === 0 || fields.includes('first_name')) {
        schema.first_name = Yup.string().required('Required')
      }
      if (fields.length === 0 || fields.includes('last_name')) {
        schema.last_name = Yup.string().required('Required')
      }
      if (fields.length === 0 || fields.includes('firm_name')) {
        schema.firm_name = Yup.string().required('Required')
      }
      if (fields.length === 0 || fields.includes('phone')) {
        schema.phone = Yup.string()
          .matches(phoneRegExp, 'Invalid phone number')
          .required('Required')
      }
      if (fields.length === 0 || fields.includes('phone_work')) {
        schema.phone_work = Yup.string()
          .matches(phoneRegExp, 'Invalid phone number')
          .required('Required')
      }
      return (
        <div className="content-padded">
          <Container>
            {loading && <ActionPageLoader />}
            <Formik
              enableReinitialize
              initialValues={{
                first_name: member.first_name,
                last_name: member.last_name,
                firm_name: member.firm_name,
                phone: member.phone,
                phone_work: member.phone_work
              }}
              validationSchema={Yup.object(schema)}
              onSubmit={async (values, { setSubmitting }) => {
                await handleSave(values)
                setSubmitting(false)
              }}
            >
              {(isValid) => (
                <Form>
                  <FormWrapper>
                    <ProductH2 className="text-center">{title}</ProductH2>
                    <div>
                      {schema.first_name && (
                        <div>
                          <FormikField
                            name="first_name"
                            label="First Name"
                            required={true}
                            show_required={true}
                          >
                            <Field
                              className="form-control"
                              name="first_name"
                              type="text"
                            />
                          </FormikField>
                        </div>
                      )}
                      {schema.last_name && (
                        <div>
                          <FormikField
                            name="last_name"
                            label="Last Name"
                            required={true}
                          >
                            <Field
                              className="form-control"
                              name="last_name"
                              type="text"
                            />
                          </FormikField>
                        </div>
                      )}
                      {schema.firm_name && (
                        <div data-tip="React-tooltip">
                          <ReactTooltip place="top" type="dark" effect="float">
                            <span>
                              The legal entity name that you file your taxes for
                            </span>
                          </ReactTooltip>
                          <FormikField
                            name="firm_name"
                            label="Firm Name"
                            required={true}
                          >
                            <Field
                              className="form-control"
                              name="firm_name"
                              type="text"
                            />
                          </FormikField>
                        </div>
                      )}
                      {schema.phone && (
                        <div>
                          <FormikField
                            name="phone"
                            label="Mobile Phone"
                            required={true}
                          >
                            <Field
                              className="form-control"
                              name="phone"
                              type="text"
                            />
                          </FormikField>
                        </div>
                      )}
                      {schema.phone_work && (
                        <div>
                          <FormikField
                            name="phone_work"
                            label="Office Phone"
                            required={true}
                          >
                            <Field
                              className="form-control"
                              name="phone_work"
                              type="text"
                            />
                          </FormikField>
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="text-center">
                        <LoadingButton
                          text="Continue"
                          isLoading={loading}
                          disabled={!isValid}
                        />
                      </div>
                    </div>
                  </FormWrapper>
                </Form>
              )}
            </Formik>
          </Container>
        </div>
      )
    }
  )
)

AccountSetup.propTypes = {
  router: PropTypes.object,
  store: PropTypes.object,
  fields: PropTypes.array,
  title: PropTypes.string
}

export default AccountSetup
