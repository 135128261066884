import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tile } from './Tile/Tile'
import { useRouter } from 'next/router'
import { inject, observer } from 'mobx-react'
import { APIURL } from '../../constants'
import Section from './Section'
import classNames from 'classnames'
import styles from './Marketplace.module.scss'
import { BannersContainer } from '../theme/banner-ads/BannersContainer'
import { useQuery } from 'react-query'
import services from '../services'
import { Tour } from './Tour'
import { useSearch } from '/components/context/search'
import styled from 'styled-components'

const Filters = ({
  siteData,
  categories,
  membershipTiers,
  setPlan,
  type,
  setType
}) => {
  const { setShowSearch } = useSearch()
  const router = useRouter()
  return (
    <div className={styles.marketplace_filter}>
      <div className="container-fluid">
        <div className="col-sm-12 col-md-6" id="search-filter">
          {categories.length > 1 && (
            <>
              <div className={styles.filter_title}>Solution Type</div>
              <select
                value={type}
                onChange={(e) => {
                  router.push(
                    router.basePath + 'marketplace/#' + e.target.value
                  )
                  setType(e.target.value)
                }}
                className="form-control"
              >
                <option value={''}>All</option>
                {categories.map((t) => (
                  <option key={`newKey${t.slug}`} value={t.slug}>
                    {t.name}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
        <div className="col-sm-12 col-md-6">
          {'ffr' == siteData.get('slug') && (
            <div>
              <div className={styles.filter_title}>Search</div>
              <FakeInput
                className="form-control"
                onClick={() => setShowSearch(true)}
              />
            </div>
          )}
          {membershipTiers.length > 1 && (
            <>
              <div className={styles.filter_title}>Membership Tier</div>
              <select
                onChange={(e) => {
                  setPlan(e.target.value)
                }}
                className="form-control"
              >
                <option value={''}>All</option>
                {membershipTiers.map((p) => (
                  <option key={`tier${p.id}`} value={p.name}>
                    {p.name}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const MarketplaceEmpty = () => (
  <div className={styles.empty_wrapper}>
    <div>
      <h2>No products here yet.</h2>
    </div>
  </div>
)

const MarketplaceDisplay = inject('store')(
  observer(
    ({
      store,
      siteData,
      products,
      type,
      setType,
      plan,
      setPlan,
      includeFilters = true,
      includeSplit = false,
      isPreview
    }) => {
      const [tier, setTier] = useState(null)
      const router = useRouter()

      const { data: bannersData } = useQuery('banners', () =>
        services.get_v2(APIURL.API_GET_BANNERS)
      )

      const { data: productCategoriesData } = useQuery(
        'product-categories',
        () => services.get_v2(APIURL.API_CATEGORIES + '?populated=1')
      )

      const { is_authenticated } = router.query
      if (
        (is_authenticated === 'true' || is_authenticated === '1') &&
        !store.isAuthenticated()
      ) {
        const next = location.pathname + location.hash
        router.push(`/idp/login?next=${window.encodeURIComponent(next)}`)
      }

      useEffect(() => {
        if (router.asPath.includes('#')) {
          const type = router.asPath.split('#')
          setType(type[1])
        }
      }, [])

      const membershipTiers = store.siteData.get('plans')

      const renderProducts = () => {
        if (isPreview) {
          return (
            <div style={{ margin: '0 auto' }}>
              <h3>Your marketplace tiles will appear here.</h3>
            </div>
          )
        } else if (!products || products.length == 0) {
          return <MarketplaceEmpty />
        } else {
          return products?.map((p, i) => (
            <Tile id={p.slug} product={p} key={p.id} index={i} />
          ))
        }
      }

      return (
        <div>
          {bannersData && (
            <BannersContainer
              banners={bannersData.response}
              placement="Marketplace"
            />
          )}
          <Tour />
          { includeFilters && (
            <Filters
              siteData={store.siteData}
              setPlan={setPlan}
              setType={setType}
              type={type}
              categories={productCategoriesData?.response || []}
              membershipTiers={membershipTiers}
            />
          )}
          <div className="container-fluid">
            <div id="marketplace-tiles">
              <div id="tiles" className={styles.tiles}>
                <div className={classNames(styles.bottom_marketplace)}>
                  <Section
                    name={type?.name}
                    key={`type-${type?.name}`}
                    tier={tier}
                  >
                    {renderProducts()}
                  </Section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  )
)

MarketplaceDisplay.propTypes = {
  products: PropTypes.array,
  banners: PropTypes.array
}

const FakeInput = styled.button`
  background-color: #fff;
  text-align: left;
  border-radius: 5px;
`

export default MarketplaceDisplay
