import { FormWrapper } from '../../../../../theme/default/base'
import { StandardInput } from '../../form-inputs/Standard'

export const MarketplaceHeroForm = ({
  description,
  subtitle,
  title,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        name="title"
        label="Title"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={subtitle}
        name="subtitle"
        label="Subtitle"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={description}
        type="textarea"
        minHeight="200px"
        name="description"
        label="Description"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}
