import { inject, observer } from 'mobx-react'
import {
  CommonPose,
  HeaderPose,
  VisbilityContainer
} from '../../../../../home/Animations'
import {
  Container,
  ChaliceImg,
  interpolate
} from '../../../../../theme/default/base'
import { MainCta } from '../../../../../theme/default/common'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getImageUrl, getOpacity, hasImage } from '../../utils'

export const FeatureList = inject('store')(
  observer(
    ({
      store,
      title,
      titleColor,
      titleBackgroundColor,
      textColor,
      backgroundColor,
      feature1,
      feature2,
      feature3,
      feature4,
      feature5,
      feature6,
      feature7,
      feature8,
      feature9,
      isPreview,
      currentComponent = {}
    }) => {
      return (
        <Wrapper textColor={textColor} backgroundColor={backgroundColor}>
          <div className="content">
            <VisbilityContainer>
              <div className="content-wrapper">
                <Header
                  backgroundColor={titleBackgroundColor}
                  textColor={titleColor}
                  dangerouslySetInnerHTML={{
                    __html: interpolate(store.siteData, title || '')
                  }}
                />
                <Container>
                  <Grid>
                    <li>
                      <CommonPose
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature1 || '')
                        }}
                      />
                    </li>
                    <li>
                      <CommonPose
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature2 || '')
                        }}
                      />
                    </li>
                    <li>
                      <CommonPose
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature3 || '')
                        }}
                      />
                    </li>
                    <li>
                      <CommonPose
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature4 || '')
                        }}
                      />
                    </li>
                    <li>
                      <CommonPose
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature5 || '')
                        }}
                      />
                    </li>
                    <li>
                      <CommonPose
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature6 || '')
                        }}
                      />
                    </li>
                    <li>
                      <CommonPose
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature7 || '')
                        }}
                      />
                    </li>
                    <li>
                      <CommonPose
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature8 || '')
                        }}
                      />
                    </li>
                    <li>
                      <CommonPose
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature9 || '')
                        }}
                      />
                    </li>
                  </Grid>
                  <Header
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                  >
                    And, So Much More!
                  </Header>
                </Container>
              </div>
            </VisbilityContainer>
          </div>
        </Wrapper>
      )
    }
  )
)

const Wrapper = styled.div`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
`

const Header = styled(CommonPose)`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  padding: 20px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;

  p {
    margin: 0;
  }
`

const Grid = styled.ul`
  padding: 40px 5px 10px 5px;
  margin: 0 auto;
  max-width: 800px;

  columns: 2;
  column-gap: 20px;
  -webkit-columns: 2;
  -moz-columns: 2;

  ${(props) => props.theme.media.tablet`
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  `}

  li {
    padding-bottom: 10px;
    margin-right: 20px;
  }
`

const More = styled.div``

const Column = styled.div`
  width: 50%;
`
