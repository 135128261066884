import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import BlogDisplay from './BlogDisplay'
import Sidebar from './Sidebar'

export const BlogBody = ({ blogs = [] }) => (
  <ContentWrapper>
    {!blogs || blogs.length === 0 ? (
      <NoBlogMessage>No blog posts yet!</NoBlogMessage>
    ) : (
      <>
        <BlogDisplay blogs={blogs} />
        <Sidebar />
      </>
    )}
  </ContentWrapper>
)
BlogBody.propTypes = {
  imageUrl: PropTypes.string
}

const NoBlogMessage = styled.h4`
  padding: 50px;
  margin: 0 auto;
`

const ContentWrapper = styled.div`
  max-width: 1170px;
  width: 100%;
  padding: 50px 15px 0 15px;
  margin: 0 auto;
  display: flex;
  @media only screen and (max-width: 990px) {
    flex-direction: column;
  }
`
