import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { Steps } from 'intro.js-react'
import { inject, observer } from 'mobx-react'

import 'intro.js/introjs.css'

const Element = ({ title, description }) => (
  <div>
    <h2>{ title }</h2>
    <p> { description }</p>
  </div>
)

Element.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

export const Tour = inject('store')(observer(({ store }) => {
  const router = useRouter()
  const [tour, setTour] = useState(false)

  if (typeof window === 'undefined') {
    return null
  }

  useEffect(() => {
    if (router.query.tour === '1') {
      setTimeout(() => {
        setTour(true)
      }, 1000)
    }
  }, [])
  const { siteData } = store

  const STEPS = [
    {
      intro: (
        <Element
          title={`Welcome to ${siteData.get('name')}`}
          description="Let's walk through the marketplace to help you maximize your time and savings!"
        />)
    },
    {
      element: '#search-filter',
      intro: (<Element title='Search the Marketplace' description='Filter all the marketplace options and find all the services you might want or need.' />),
      position: 'center'
    },
    {
      element: '.marketplace-tile-0',
      intro: (<Element title='Find a product' description='Once you find a product you are interested in, click to learn more!' />),
      position: 'right'
    },
    {
      element: '.intercom-lightweight-app-launcher',
      intro: (<Element title='Questions? Compliments?' description='If you need any help or have any feedback, we are always available.' />)
    }
  ]


  return (
    <Steps
      enabled={tour}
      steps={STEPS}
      initialStep={0}
      onBeforeExit={() => {
        window.scrollTo(0, 0)
      }}
      onExit={() => {
        setTour(false)
      }}
      onBeforeChange={(nextStepIndex) => {
        if (nextStepIndex === 3) {
          window.scrollTo(0, 0)
        }
      }}
      options={{
        showStepNumbers: true,
        showProgress: false
      }} />
  )
}))
