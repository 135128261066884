import { FormWrapper } from '../../../../../theme/default/base'
import { BooleanInput } from '../../form-inputs/Boolean'
import { ColorPicker } from '../../form-inputs/ColorPicker'
import { HtmlInput } from '../../form-inputs/Html'
import { MediaInputPB } from '../../form-inputs/Media'
import { OpacityInput } from '../../form-inputs/Opacity'

export const HeroForm = ({
  text_color = 'black',
  title,
  subtitle,
  background_image,
  logo,
  has_cta,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <BooleanInput
        value={has_cta}
        name="has_cta"
        label="Call to action"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={subtitle}
        name="subtitle"
        label="SubTitle"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        value={text_color}
        label="Text Color"
        name="text_color"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={background_image}
        label="Background Image"
        name="background_image"
        setCurrentComponent={setCurrentComponent}
      />
      {background_image && (
        <OpacityInput
          value={background_image?.opacity}
          label="Background Image Opacity"
          name="background_image"
          setCurrentComponent={setCurrentComponent}
        />
      )}
      <MediaInputPB
        image={logo}
        label="Logo"
        name="logo"
        setCurrentComponent={setCurrentComponent}
      />
      {logo && (
        <OpacityInput
          value={logo?.opacity}
          label="Logo Opacity"
          name="logo"
          setCurrentComponent={setCurrentComponent}
        />
      )}
    </FormWrapper>
  )
}
