import React, { useEffect } from 'react'
import { VisbilityContainer } from '../../../../../home/Animations'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const MarketoForm = ({ src, form_id, form_url, form_uuid }) => {
  let mounted = React.useRef(false)
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.onload = () => {
      if (!mounted.current) {
        window.MktoForms2.loadForm(form_url, form_uuid, form_id)
      }
      mounted.current = true
    }
    script.onerror = () => {
      console.error('Something went wrong trying to load the partner form.')
    }
    document.body.appendChild(script)
    return () => document.body.removeChild(script)
  })
  return (
    <VisbilityContainer>
      <MarketoFormStyle>
        <form id={`mktoForm_${form_id}`}></form>
      </MarketoFormStyle>
    </VisbilityContainer>
  )
}

MarketoForm.propTypes = {
  src: PropTypes.string,
  formId: PropTypes.number,
  formUrl: PropTypes.string,
  formUUID: PropTypes.string
}

const PartnerForm = styled.div`
  box-shadow: 2px 2px 32px rgb(14 34 64 / 20%);
  border-radius: 5px;
  padding: 50px;
`

const MarketoFormStyle = styled(PartnerForm)`
  form {
    width: 100% !important;
  }
  label {
    width: 200px !important;
  }
  input,
  button {
    display: block;
    padding: 10px !important;
    border: #ccc solid 1px;
    border-radius: 5px;
    font-size: 1rem !important;
    width: 100% !important;
  }
  .mktoAsterix {
    display: none !important;
    float: none !important;
  }
  .mktoButtonRow {
    display: block;
    padding: 20px 0;

    span {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .mktoFieldWrap {
    display: flex;
    align-items: center;
  }
`
