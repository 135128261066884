import { FormWrapper } from '../../../../../theme/default/base'
import { StandardInput } from '../../form-inputs/Standard'

export const HubspotFormForm = ({
  id,
  form_id,
  portal_id,
  setCurrentComponent,
  sideName,
  index
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={form_id}
        name={'form_id'}
        label={'Form ID'}
        setCurrentComponent={setCurrentComponent}
        onChange={
          sideName
            ? (e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props[sideName][index].props.form_id = e.target.value
                  return temp
                })
              }
            : null
        }
      />
      <StandardInput
        value={portal_id}
        name={'portal_id'}
        label={'Portal ID'}
        setCurrentComponent={setCurrentComponent}
        onChange={
          sideName
            ? (e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props[sideName][index].props.portal_id = e.target.value
                  return temp
                })
              }
            : 0
        }
      />
    </FormWrapper>
  )
}
