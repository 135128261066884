import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { VisbilityContainer, HeaderPose, CommonPose } from '../home/Animations'

import { Container, VerticalPad } from '../theme/default/base'

const SplitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 50px 0;

  ${(props) => props.theme.media.desktop`
    flex-direction: column;
    padding: 0;
  `}
`

const H1 = styled(HeaderPose)`
  font-size: 4rem;
  margin: 0;
  padding: 0;

  ${(props) => props.theme.media.desktop`
    padding-bottom: 20px;
    font-size: 3rem;
  `}
`

const H2 = styled.h2`
  font-size: 1.5rem;
  padding-bottom: 20px;
  font-weight: 400;
`
const P = styled.p`
  font-size: 1.2rem;
`

const Col = styled(CommonPose)`
  width: 50%;
  flex: 1 0 auto;

  &:first-child {
    padding-right: 20px;
  }
  &:last-child {
    padding-left: 20px;
  }

  ${(props) => props.theme.media.desktop`
    width: 100%;
    &:first-child {
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
    }
  `}
`

const MarketplaceHero = ({
  title = 'Explore our Marketplace',
  subtitle = "We've done the heavy lifting for you.",
  description = 'Gain exclusive access to best-of-need products and services.'
}) => {
  return (
    <VisbilityContainer>
      <Container>
        <VerticalPad>
          <SplitWrapper>
            <Col>
              <H1>{title}</H1>
            </Col>
            <Col>
              <H2>{subtitle}</H2>
              <P>{description}</P>
            </Col>
          </SplitWrapper>
        </VerticalPad>
      </Container>
    </VisbilityContainer>
  )
}

MarketplaceHero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string
}

export default MarketplaceHero
