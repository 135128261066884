import { inject, observer } from 'mobx-react'
import { CommonPose, VisbilityContainer } from '../../../../../home/Animations'
import { ChaliceImg, interpolate } from '../../../../../theme/default/base'
import { MainCta } from '../../../../../theme/default/common'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { getImageUrl, getOpacity, hasImage } from '../../utils'

export const FeatureGrid = inject('store')(
  observer(
    ({
      store,
      textColor,
      backgroundColor,
      feature1,
      feature1Image,
      feature2,
      feature2Image,
      feature3,
      feature3Image,
      feature4,
      feature4Image,
      isPreview,
      currentComponent = {}
    }) => {
      return (
        <Wrapper>
          {/* <Sloped backgroundColor={backgroundColor} /> */}
          <Content textColor={textColor} backgroundColor={backgroundColor}>
            <div className="content">
              <VisbilityContainer>
                <Center>
                  <Grid>
                    <Feature borderWidth="0 1px 1px 0">
                      {hasImage(feature1Image) && (
                        <ImageWrapper>
                          <ChaliceImg
                            src={getImageUrl(feature1Image)}
                            opacity={getOpacity(feature1Image)}
                          />
                        </ImageWrapper>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature1 || '')
                        }}
                      />
                    </Feature>
                    <Feature borderWidth="0 0 1px 1px">
                      {hasImage(feature2Image) && (
                        <ImageWrapper>
                          <ChaliceImg
                            src={getImageUrl(feature2Image)}
                            opacity={getOpacity(feature2Image)}
                          />
                        </ImageWrapper>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature2 || '')
                        }}
                      />
                    </Feature>
                    <Feature borderWidth="1px 1px 0 0">
                      {hasImage(feature3Image) && (
                        <ImageWrapper>
                          <ChaliceImg
                            src={getImageUrl(feature3Image)}
                            opacity={getOpacity(feature3Image)}
                          />
                        </ImageWrapper>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature3 || '')
                        }}
                      />
                    </Feature>
                    <Feature borderWidth="1px 0 0 1px">
                      {hasImage(feature4Image) && (
                        <ImageWrapper>
                          <ChaliceImg
                            src={getImageUrl(feature4Image)}
                            opacity={getOpacity(feature4Image)}
                          />
                        </ImageWrapper>
                      )}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, feature4 || '')
                        }}
                      />
                    </Feature>
                  </Grid>
                </Center>
              </VisbilityContainer>
            </div>
          </Content>
        </Wrapper>
      )
    }
  )
)

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const Content = styled.div`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  padding: 25px 0;
`

const Sloped = styled.div`
  position: absolute;
  width: 125%;
  height: 50px;
  top: 25px;
  right: -5%;
  transform: rotate(358deg);
  background-color: ${(props) => props.backgroundColor};
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
`

const Grid = styled.div`
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 400px);
  grid-template-rows: repeat(2, 150px);

  ${(props) => props.theme.media.tablet`
    display: flex;
    flex-direction: column;
  `}
`

const Feature = styled(CommonPose)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${(props) => props.borderColor || '#fff'};
  font-size: 1.3rem;
  font-weight: 600;
  ${(props) =>
    props.borderWidth &&
    css`
      border-width: ${props.borderWidth};
    `}
  padding: 0 20px;

  ${(props) => props.theme.media.tablet`
    border: 0;
  `}
`

const ImageWrapper = styled.div`
  height: 150px;
  width: 150px;
`

const More = styled.div``
