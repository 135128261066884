import { FormWrapper } from '../../../../../theme/default/base'
import { StandardInput } from '../../form-inputs/Standard'

export const MarketoFormForm = ({
  id,
  form_id,
  form_url,
  src,
  form_uuid,
  setCurrentComponent,
  sideName,
  index
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={src}
        name={'src'}
        label={'Src'}
        setCurrentComponent={setCurrentComponent}
        onChange={
          sideName
            ? (e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props[sideName][index].props.src = e.target.value
                  return temp
                })
              }
            : null
        }
      />
      <StandardInput
        value={form_id}
        name={'form_id'}
        label={'Form ID'}
        setCurrentComponent={setCurrentComponent}
        onChange={
          sideName
            ? (e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props[sideName][index].props.form_id = e.target.value
                  return temp
                })
              }
            : null
        }
      />
      <StandardInput
        value={form_url}
        name={'form_url'}
        label={'Form Url'}
        setCurrentComponent={setCurrentComponent}
        onChange={
          sideName
            ? (e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props[sideName][index].props.form_url = e.target.value
                  return temp
                })
              }
            : null
        }
      />
      <StandardInput
        value={form_uuid}
        name={'form_uuid'}
        label={'Form UUID'}
        setCurrentComponent={setCurrentComponent}
        onChange={
          sideName
            ? (e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props[sideName][index].props.form_uuid = e.target.value
                  return temp
                })
              }
            : null
        }
      />
    </FormWrapper>
  )
}
