import { inject, observer } from 'mobx-react'
import {
  CommonPose,
  HeaderPose,
  VisbilityContainer
} from '../../../../../home/Animations'
import { ChaliceImg, interpolate } from '../../../../../theme/default/base'
import { MainCta } from '../../../../../theme/default/common'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { getImageUrl, getOpacity, hasImage } from '../../utils'
import styled from 'styled-components'

export const Hero = inject('store')(
  observer(
    ({
      id,
      store,
      title,
      subtitle,
      logo,
      background_image,
      has_cta = true,
      text_color = 'black',
      text_shadow = false,
      isPreview,
      currentComponent = {}
    }) => (
      <Wrapper
        textColor={text_color}
        textShadow={text_shadow}
        className="hero-block"
      >
        <div className="content">
          <VisbilityContainer>
            <div className="content-wrapper description">
              {hasImage(logo) && (
                <LogoWrapper>
                  <ChaliceImg
                    key={id}
                    src={getImageUrl(logo)}
                    opacity={getOpacity(logo)}
                    alt="Logo Image"
                  />
                </LogoWrapper>
              )}
              <HeaderPose
                dangerouslySetInnerHTML={{
                  __html: interpolate(store.siteData, title)
                }}
              />
              <CommonPose
                className="secondary-header"
                dangerouslySetInnerHTML={{
                  __html: interpolate(store.siteData, subtitle)
                }}
              />
              <MainCta hasCta={has_cta} isPreview={isPreview} />
            </div>
          </VisbilityContainer>
        </div>
        <Background>
          {hasImage(background_image) && (
            <Head>
              <link
                rel="preload"
                as="image"
                href={getImageUrl(background_image)}
              />
            </Head>
          )}
          {hasImage(background_image) && (
            <ChaliceImg
              key={id}
              background={true}
              opacity={background_image.opacity}
              src={getImageUrl(background_image)}
              alt="People Working"
            />
          )}
        </Background>
      </Wrapper>
    )
  )
)

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  logoUrl: PropTypes.string,
  backgroundUrl: PropTypes.string,
  cta: PropTypes.object
}

const LogoWrapper = styled.div`
  height: 200px;
  .next-image {
    height: 200px !important;
    width: auto;
  }
  @media (max-width: 768px) {
    .next-image {
      height: 150px !important;
    }
  }
`

const Background = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`

const Wrapper = styled.div`
  color: ${(props) => props.textColor};
  text-shadow: ${(props) =>
    props.textShadow ? '2px 2px rgba(0, 0, 0, 0.3)' : null};

  h1,
  h2,
  h3,
  h4 {
    color: ${(props) => props.textColor};
    text-shadow: ${(props) =>
      props.textShadow ? '2px 2px rgba(0, 0, 0, 0.3)' : null};
  }
`
