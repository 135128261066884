import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MdPersonOutline } from 'react-icons/md'
import { Img } from 'react-image'
import { motion } from 'framer-motion'

import { Container, VerticalPad, ChaliceImg } from './base'

import {
  VisbilityContainer
} from '../../home/Animations'

const List = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Member = styled(motion.div)`
  flex: 1 0 30%;
  border: #eee solid 1px;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px 0;
`

const H2 = styled.h2`
  width: 50%;
`

const Description = styled.p`
  width: 50%;
`

const Name = styled.div`
  font-size: 1.5rem !important;
  text-align: center;
  padding: 10px 0;
`

const Title = styled.div`
  font-size: 1.1rem !important;
  text-align: center;
`

const Image = styled.div`
  max-width: 300px;
  height: 300px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 auto;
    height: 100%;
    width: auto;
  }

  svg {
    margin: 0 auto;
    width: auto;
    height: 100%;
  }
`

export const TeamList = ({ title, description, team }) => (
  <VisbilityContainer>
    <Container>
      <VerticalPad>
        <Header>
          <H2>Meet our Team</H2>
          <Description>{ description }</Description>
        </Header>
        <List>
          { team.map((t, i) => (
            <Member key={i}>
              <Image>
                <ChaliceImg src={t.imageUrl} />
              </Image>
              <Name>{t.name}</Name>
              <Title>{t.title}</Title>
            </Member>
          ))}
        </List>
      </VerticalPad>
    </Container>
  </VisbilityContainer>
)

TeamList.propTypes = {
  team: PropTypes.array
}
