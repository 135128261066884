import { FormWrapper } from '../../../../../theme/default/base'
import { HtmlInput } from '../../form-inputs/Html'
import { MediaInputPB } from '../../form-inputs/Media'

export const BlogHeroForm = ({
  title = 'Blog',
  image,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <HtmlInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={image}
        label="Image"
        name="image"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}
