import {
  CommonPose,
  LinkPose,
  VisbilityContainer
} from '../../../../../home/Animations'
import Link from 'next/link'
import { ChaliceImg } from '../../../../../theme/default/base'
import PropTypes from 'prop-types'
import { getImageUrl, getOpacity, hasImage } from '../../utils'

export const Pillars = ({
  id,
  title,
  subtitle,
  pillars,
  text_color = 'black',
  text_shadow = false,
  bg_color = '#fff',
  currentComponent = {}
}) => {
  return (
    <VisbilityContainer>
      <div className="pillar-block">
        <div className="content">
          <CommonPose>
            <h2>{title}</h2>
          </CommonPose>
          <CommonPose>
            <h3>{subtitle}</h3>
          </CommonPose>
          <div className="pillars">
            {pillars.map((p, i) => (
              <Link passHref key={i} href={p.url || '#'}>
                <LinkPose key={p.title} className="pillar">
                  <div className="pillar-image">
                    {hasImage(p.image) && (
                      <ChaliceImg
                        key={id}
                        src={getImageUrl(p.image)}
                        opacity={getOpacity(p.image)}
                      />
                    )}
                  </div>
                  <h4>{p.title}</h4>
                  <p>{p.description}</p>
                </LinkPose>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </VisbilityContainer>
  )
}

Pillars.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  pillars: PropTypes.array
}
