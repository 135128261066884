import React from 'react'
import styled from 'styled-components'
import { HeaderPose } from '../../../../../home/Animations'
import { ChaliceImg, interpolate } from '../../../../../theme/default/base'

import { getImageUrl, hasImage } from '../../utils'

import { inject, observer } from 'mobx-react'

export const BlogHero = inject('store')(
  observer(
    ({ store, title, image, publishDate, readingTime, imageUrl = null }) => {
      let iUrl = hasImage(image) ? getImageUrl(image) : imageUrl
      if (!iUrl) {
        iUrl = '/assets/images/blog/hero.webp'
      }
      const formattedDate = new Date(publishDate)
      return (
        <Hero>
          <Container>
            <Headline>
              <HeaderPose
                dangerouslySetInnerHTML={{
                  __html: interpolate(store.siteData, title)
                }}
              />
              {publishDate && readingTime && (
                <div>
                  {formattedDate.toDateString()} ∙ {readingTime}
                </div>
              )}
            </Headline>
            <ImageWrapper>
              {iUrl && <ChaliceImg src={iUrl} alt="" />}
            </ImageWrapper>
          </Container>
        </Hero>
      )
    }
  )
)

const Hero = styled.div`
  position: relative;
  min-height: 400px;
  overflow: hidden;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 400px;
  flex-basis: 50%;
  overflow: hidden;
  opacity: 1;

  @media (max-width: 768px) {
    opacity: 0.25;
    flex-basis: 100%;
  }
`

const Container = styled.div`
  padding: 0;
  margin: 0 auto;
  max-width: 1170px;
  display: flex;
  @media (max-width: 768px) {
    padding: 0;
  }
`

const Headline = styled.div`
  position: relative;
  padding: 30px 0 30px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #121212;
  z-index: 11;
  min-height: 400px;
  flex-basis: 50%;

  @media (max-width: 768px) {
    position: absolute;
    width: 100%;
    text-shadow: 1px 1px 2px rgba(#fff, 0.5);
    padding: 30px 15px;
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 600;
    margin: 0 0 5px 0;
    padding: 0;
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }
`
