import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { VisbilityContainer } from '../../../../../home/Animations'

export const HubspotEmbed = ({ portalId, formId }) => {
  let mounted = React.useRef(false)
  const target = 'partner-form' // `${formId}-wrapper`
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.async = true
    script.onload = () => {
      if (!mounted.current) {
        window.hbspt.forms.create({
          portalId,
          formId,
          target: `#${target}`
        })
      }
      mounted.current = true
    }
    script.onerror = () => {
      console.error('Something went wrong trying to load the partner form.')
    }
    document.body.appendChild(script)
    return () => document.body.removeChild(script)
  })
  return <PartnerForm className="partner-form" id={target}></PartnerForm>
}

HubspotEmbed.propTypes = {
  portalId: PropTypes.string,
  formId: PropTypes.string
}

export const HubspotForm = ({ portal_id, form_id }) => (
  <VisbilityContainer lazyLoad={true}>
    <HubspotEmbed portalId={portal_id} formId={form_id} />
  </VisbilityContainer>
)

const PartnerForm = styled.div`
  box-shadow: 2px 2px 32px rgb(14 34 64 / 20%);
  border-radius: 5px;
  padding: 50px;
`

HubspotForm.propTypes = {
  portalId: PropTypes.string,
  formId: PropTypes.string
}
