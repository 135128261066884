import React from 'react'
import { inject, observer } from 'mobx-react'

@inject('store')
@observer
class ActionPageLoader extends React.Component {
  render () {
    return (
      <div className="category-content-tab">
        <div className="orverlay-loader">
          <img alt={'Action loader'} className="fullpage-loader" src="/assets/images/loader.gif" />
        </div>
      </div>
    )
  }
}

export default ActionPageLoader
