import { CommonPose } from '../../../../../home/Animations'
import PropTypes from 'prop-types'

export const HtmlBlock = ({ content }) => (
  <CommonPose dangerouslySetInnerHTML={{ __html: content }} />
)

HtmlBlock.propTypes = {
  content: PropTypes.string
}
