import { FormWrapper } from '../../../../../theme/default/base'
import { BooleanInput } from '../../form-inputs/Boolean'
import { ColorPicker } from '../../form-inputs/ColorPicker'
import { HtmlInput } from '../../form-inputs/Html'
import { MediaInputPB } from '../../form-inputs/Media'
import { OpacityInput } from '../../form-inputs/Opacity'

export const FeatureGridForm = ({
  textColor,
  backgroundColor,
  feature1,
  feature1Image,
  feature2,
  feature2Image,
  feature3,
  feature3Image,
  feature4,
  feature4Image,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <ColorPicker
        name={'textColor'}
        label="Text Color"
        value={textColor}
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        name={'backgroundColor'}
        label="Background Color"
        value={backgroundColor}
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature1}
        name="feature1"
        label="Feature 1"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={feature1Image}
        label="Feature 1 Image"
        name="feature1Image"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature2}
        name="feature2"
        label="Feature 2"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={feature2Image}
        label="Feature 2 Image"
        name="feature2Image"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature3}
        name="feature3"
        label="Feature 3"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={feature3Image}
        label="Feature 3 Image"
        name="feature3Image"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature4}
        name="feature4"
        label="Feature 4"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={feature4Image}
        label="Feature 4 Image"
        name="feature4Image"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}
