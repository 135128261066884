import { FormWrapper } from '../../../../../theme/default/base'
import { BooleanInput } from '../../form-inputs/Boolean'
import { StandardInput } from '../../form-inputs/Standard'

const possibleFields = [
  { name: 'first_name', label: 'First Name' },
  { name: 'last_name', label: 'Last Name' },
  { name: 'firm_name', label: 'Firm Name' },
  { name: 'phone', label: 'Phone' },
  { name: 'phone_work', label: 'Work Phone' }
]

export const AccountSetupForm = ({
  id,
  title,
  fields,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        name="title"
        label="Title"
        type="textarea"
        setCurrentComponent={setCurrentComponent}
      />
      {possibleFields.map((field) => {
        return (
          <BooleanInput
            key={field.name}
            label={field.label}
            value={fields.includes(field.name)}
            onChange={(e) =>
              setCurrentComponent((prev) => {
                const temp = { ...prev }
                if (e.target.value === 'true') {
                  temp.props.fields.push(field.name)
                } else {
                  temp.props.fields = temp.props.fields.filter(
                    (f) => f !== field.name
                  )
                }
                return temp
              })
            }
          />
        )
      })}
    </FormWrapper>
  )
}
