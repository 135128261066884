import styled from 'styled-components'
import PropTypes from 'prop-types'
import { CommonPose, VisbilityContainer } from '../../../../../home/Animations'
import { Container, VerticalPad } from '../../../../../theme/default/base'
import { ComponentRender } from '../../../../../theme/default'

export const Split = ({ title, left, right, bg_color }) => {
  return (
    <VisbilityContainer>
      <Container bgColor={bg_color}>
        <VerticalPad>
          <H2>{title}</H2>
          <SplitWrapper>
            <Col>{ComponentRender(left)}</Col>
            <Col>{ComponentRender(right)}</Col>
          </SplitWrapper>
        </VerticalPad>
      </Container>
    </VisbilityContainer>
  )
}

Split.propTypes = {
  title: PropTypes.string,
  left: PropTypes.object,
  right: PropTypes.object
}

const SplitWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${(props) => props.theme.media.tablet`
    flex-direction: column;
    padding: 20px 0 20px 0;
  `}
`

const H2 = styled.h2`
  font-size: 2rem;
  text-align: center;
`

const Col = styled(CommonPose)`
  width: 50%;
  flex: 1 0 auto;

  &:first-child {
    padding-right: 20px;
  }
  &:last-child {
    padding-left: 20px;
  }

  ${(props) => props.theme.media.tablet`
    width: 100%;
    &:first-child {
      padding-right: 0;
    }
    &:last-child {
      padding-left: 0;
    }
  `}
`
