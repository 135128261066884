import React from 'react'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useQuery } from 'react-query'
import Slider from 'react-slick'

import services from '/components/services'
import { APIURL, PAGEURL } from '/constants'
import { radial, FormWrapper } from '/components/theme/default/base'

import { StandardInput } from '/components/admin/page/page_builder/form-inputs/Standard'

// TODO: remove this dependency
import { Container } from '/components/theme/earthlink/theme'

const ParadeCarousel = ({ products }) => {
  const settings = {
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    dots: false,
    speed: 5000,
    rows: 2,
    slidesToShow: Math.min(6, products.length),
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(6, products.length)
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: Math.min(4, products.length)
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  }
  return (
    <SliderWrapper {...settings}>
      {products.map((p, i) => (
        <ParadeIcon key={i} product={p} />
      ))}
    </SliderWrapper>
  )
}

export const LogoParade = ({
  title = '100+ products available',
  subtitle = "You'll find something you love"
}) => {
  const { data } = useQuery(
    ['products'],
    () => services.get_v2(`${APIURL.API_MARKETPLACE_SEARCH}?limit=12`),
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )
  return (
    <Section>
      <AnimatePresence>
        <Container>
          <SectionHeader
            center
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
          >
            {title}
          </SectionHeader>
          <SectionSubtitle
            center
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.9 }}
          >
            {subtitle}
          </SectionSubtitle>
          <ParadeCarousel products={data?.response || []} />
        </Container>
      </AnimatePresence>
    </Section>
  )
}

export const LogoParadeForm = ({
  title = '100+ Products Available',
  subtitle = "You'll find something you love",
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={subtitle}
        name="subtitle"
        label="Subtitle"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}

const Section = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  min-height: 400px;

  ${(props) =>
    props.coloredBg &&
    `
    background-color: ${props.theme.colors.secondary};
    background: ${radial(props.theme.colors.secondary)};
    padding: 50px 0;
    color: #fff;
  `}

  ${(props) =>
    props.grey &&
    `
    background-color: #f2f2f2;
  `}

  ${(props) => props.theme.media.tablet`
    padding: 100px 10px;
  `}
`

const SectionHeader = styled(motion.h2)`
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  color: ${(props) =>
    props.white ? props.theme.colors.white : props.theme.colors.secondary};
  font-weight: 700;
  font-size: 32px;
  padding: 10px 0;
  margin: 0;
`

const SectionSubtitle = styled(motion.h3)`
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  font-weight: 400;
  font-size: 18px;
  padding: 10px 0;
  margin: 0 auto;
  max-width: 600px;
`

const ParadeIconWrapper = styled.a`
  width: 220px;
  height: 100px;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.25s;
  align-items: center;
  justify-content: center;

  &:hover {
    transition: all 0.25s;
    opacity: 1 !important;
    transform: scale(1.05);
  }
`

const ParadeImg = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`

const ParadeIcon = ({ product }) => (
  <Link passHref href={PAGEURL.PRODUCT(product.slug)}>
    <ParadeIconWrapper className="parade-icon-wrapper">
      <ParadeImg src={product.logo_url} alt={product.product_name} />
    </ParadeIconWrapper>
  </Link>
)

const TileImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;

  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`

const SliderWrapper = styled(Slider)`
  display: flex;
  padding: 20px 0;

  .slick-list {
    display: flex;
    max-width: 90vw;
    overflow: hidden;
    width: 100%;
  }

  .slick-dots {
    display: block;
  }

  .slick-track {
    flex: 1 0 auto;
    display: flex;
    flex-flow: nowrap;
    flex-direction: row;
    align-items: stretch;
    display: flex;
    justify-content: center;
    padding: 10px 0;

    ${(props) => props.theme.media.phone`
      margin: 0 auto;
    `}

    & > div {
      flex: 1 1 auto;
      display: flex;
      height: 100%;
    }

    & > div > div {
    }

    .slick-slide {
      display: flex;
      flex-direction: column;
      float: none;
      flex: 1 0 auto;
      height: 100%;
      transition: all 0.25s;

      & > div {
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        height: 100%;
      }

      &:hover {
        transform: scale(1.01);
        transition: all 0.25s;
      }

      img {
        flex: 1 0 auto;
        width: 100%;
      }
    }
  }
`
