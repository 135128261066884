import styled from 'styled-components'

export const OpacityInput = ({
  value = 1,
  name,
  setCurrentComponent,
  label
}) => {
  return (
    <Wrapper>
      <p>{label}</p>
      <input
        id="opacity"
        name={name}
        type="range"
        min="0"
        max="100"
        value={value * 100}
        onChange={(e) => {
          setCurrentComponent((prev) => {
            const temp = { ...prev }
            temp.props[name].opacity = e.target.value / 100
            return temp
          })
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
`
