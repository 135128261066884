import { FormWrapper } from '../../../../../theme/default/base'
import { HtmlInput } from '../../form-inputs/Html'

export const HtmlBlockForm = ({
  id,
  content,
  setCurrentComponent,
  sideName,
  index
}) => {
  return (
    <FormWrapper>
      <HtmlInput
        value={content}
        name={'content'}
        label={'Content'}
        setCurrentComponent={setCurrentComponent}
        onChange={
          sideName
            ? (e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props[sideName][index].props.content = e
                  return temp
                })
              }
            : null
        }
      />
    </FormWrapper>
  )
}
