import React from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import Link from 'next/link'

const BlogCard = ({ id, title, slug, imageUrl, publishDate, readTime }) => {
  if (!imageUrl) imageUrl = '/assets/images/blog/fallback.webp'
  const formattedDate = new Date(publishDate)
  return (
    <Card>
      <Link href={`/blog/${slug}`}>
        <ImageWrapper>
          <Image
            alt="Blog header"
            src={imageUrl}
            layout="fill"
            objectFit="cover"
            quality={100}
          />
        </ImageWrapper>
      </Link>

      <SubText>
        {formattedDate.toDateString()} ∙ {readTime}
      </SubText>
      <Link href={`/blog/${slug}`}>
        <Title>{title}</Title>
      </Link>
    </Card>
  )
}

export default BlogCard

const Card = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  transition: all 0.3s linear;
  width: 40%;

  &:hover {
    box-shadow: 0 0 30px rgb(2 32 84 / 30%);
  }

  @media (max-width: 990px) {
    width: 100%;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: 300px;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
`

const SubText = styled.div`
  display: flex;
  font-size: 0.8rem;
`

const Title = styled.h3`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 10px;

  &:hover {
    cursor: pointer;
  }
`
