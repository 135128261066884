import { FormWrapper } from '../../../../../theme/default/base'
import { BooleanInput } from '../../form-inputs/Boolean'
import { ColorPicker } from '../../form-inputs/ColorPicker'
import { StandardInput } from '../../form-inputs/Standard'
import { HtmlInput } from '../../form-inputs/Html'
import { MediaInputPB } from '../../form-inputs/Media'
import { OpacityInput } from '../../form-inputs/Opacity'

export const HeroCaptureForm = ({
  text_color = 'black',
  title,
  end_title,
  subtitle,
  cta_text,
  benefit1,
  benefit2,
  benefit3,
  logo,
  side_image,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        name="title"
        label="Title"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={end_title}
        name="end_title"
        label="Typewriter Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={subtitle}
        name="subtitle"
        label="SubTitle"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={cta_text}
        name="cta_text"
        label="CTA Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={benefit1}
        name="benefit1"
        label="Benefit 1"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={benefit2}
        name="benefit2"
        label="Benefit 2"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={benefit3}
        name="benefit3"
        label="Benefit 3"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        value={text_color}
        label="Text Color"
        name="text_color"
        setCurrentComponent={setCurrentComponent}
      />
      <MediaInputPB
        image={logo}
        label="Logo"
        name="logo"
        setCurrentComponent={setCurrentComponent}
      />
      {logo && (
        <OpacityInput
          value={logo?.opacity}
          label="Logo Opacity"
          name="logo"
          setCurrentComponent={setCurrentComponent}
        />
      )}

      <MediaInputPB
        image={side_image}
        label="Side Image"
        name="side_image"
        setCurrentComponent={setCurrentComponent}
      />
      {side_image && (
        <OpacityInput
          value={side_image?.opacity}
          label="Side Image Opacity"
          name="side_image"
          setCurrentComponent={setCurrentComponent}
        />
      )}
    </FormWrapper>
  )
}
