import { inject, observer } from 'mobx-react'
import { Formik, Form, Field } from 'formik'
import { useRouter } from 'next/router'
import * as Yup from 'yup'
import styled from 'styled-components'
import { FiCheck } from 'react-icons/fi'
import Typewriter from 'typewriter-effect'

import { CommonPose, VisbilityContainer } from '../../../../../home/Animations'
import FormikField from '../../../../../forms/FormikField'
import { MainCta } from '../../../../../theme/default/common'
import LoadingButton from '../../../../../common/LoadingButton'
import { ChaliceImg, interpolate } from '../../../../../theme/default/base'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { getImageUrl, hasImage, getOpacity } from '../../utils'
import services from '../../../../../services'
import { APIURL, PAGEURL } from '../../../../../../constants'

export const HeroCapture = inject('store')(
  observer(
    ({
      id,
      store,
      title,
      end_title,
      logo,
      subtitle,
      cta_text = 'Get Started',
      benefit1 = null,
      benefit2 = null,
      benefit3 = null,
      side_image,
      text_color = 'black',
      text_shadow = false,
      isPreview
    }) => {
      const router = useRouter()
      const handleSubmit = async (values, setErrors, setSubmitting) => {
        try {
          const data = { email: values.email }
          const res = await services.post_v2(APIURL.API_LEAD_CAPTURE, data)
          if (res.statusCode === 200) {
            window.localStorage.setItem('chaliceconnect_email', values.email)
            router.push(PAGEURL.SIGNUP)
            return
          } else if (res.statusCode === 500) {
            setErrors({
              global:
                'Unable to submit the form at this time. Please try again later'
            })
          } else {
            setErrors({
              global: res.message
            })
          }
        } catch (e) {
          console.error(e)
        }
        setSubmitting(false)
      }

      let form = null
      if (store.isAuthenticated()) {
        form = <MainCta hasCta={true} isPreview={isPreview} />
      } else {
        form = (
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object({
              email: Yup.string().required('Required')
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              await handleSubmit(values, setErrors, setSubmitting)
            }}
          >
            {({ isValid, isSubmitting, errors }) => (
              <Form>
                <div>
                  {errors.global && (
                    <div className="row">
                      <div className="col-sm-12">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: errors.global
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <CommonPose>
                    <FormikField name="email">
                      <Field
                        className="form-control"
                        name="email"
                        type="text"
                        placeholder="Your Email"
                      />
                    </FormikField>
                  </CommonPose>
                  <CommonPose className="text-center">
                    <LoadingButton
                      text={cta_text}
                      isLoading={isSubmitting}
                      disabled={!isValid}
                    />
                  </CommonPose>
                </div>
              </Form>
            )}
          </Formik>
        )
      }

      return (
        <Wrapper textColor={text_color} textShadow={text_shadow}>
          <Container className="container">
            <ContentWrapper>
              <Col zindex={10}>
                <VisbilityContainer>
                  <SideText>
                    <CommonPose>
                      {hasImage(logo) && (
                        <LogoWrapper>
                          <ChaliceImg
                            key={id}
                            src={getImageUrl(logo)}
                            alt="Logo Image"
                            opacity={getOpacity(logo)}
                          />
                        </LogoWrapper>
                      )}
                      <Header>
                        <Preface>{interpolate(store.siteData, title)}</Preface>
                        <Typewriter
                          options={{
                            strings: (end_title || '')
                              .split(',')
                              .map((s) => s.trim()),
                            deleteSpeed: 'natural',
                            autoStart: true,
                            loop: true,
                            delay: 20
                          }}
                        />
                      </Header>
                    </CommonPose>
                    <CommonPose>
                      <Subheader
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, subtitle)
                        }}
                      />
                    </CommonPose>
                    {form}
                    <div>
                      <Ul>
                        {benefit1 && (
                          <Li>
                            <FiCheck /> {benefit1}
                          </Li>
                        )}
                        {benefit2 && (
                          <Li>
                            <FiCheck /> {benefit2}
                          </Li>
                        )}
                        {benefit3 && (
                          <Li>
                            <FiCheck /> {benefit3}
                          </Li>
                        )}
                      </Ul>
                    </div>
                  </SideText>
                </VisbilityContainer>
              </Col>
              <Col zindex={9}>
                {hasImage(side_image) && (
                  <Head>
                    <link
                      rel="preload"
                      as="image"
                      href={getImageUrl(side_image)}
                    />
                  </Head>
                )}
                {hasImage(side_image) && (
                  <SideImage>
                    <ChaliceImg
                      key={id}
                      background={true}
                      opacity={side_image.opacity}
                      src={getImageUrl(side_image)}
                      alt="People Working"
                    />
                  </SideImage>
                )}
              </Col>
            </ContentWrapper>
          </Container>
        </Wrapper>
      )
    }
  )
)

HeroCapture.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  backgroundUrl: PropTypes.string,
  cta: PropTypes.object
}

const SideText = styled.div`
  position: relative;
  max-width: 450px;
`

const SideImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  min-height: 500px;

  @media (max-width: 768px) {
    opacity: 0.2;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .next-image {
    height: auto;
    width: 100% !important;
  }
`

const Header = styled.div`
  font-size: 2.5rem;
  font-weight: 500;
  text-align: left;
  line-height: 3rem;
  margin: 10px 0;

  p {
    padding: 0
    margin: 0
    display: block;
  }

  strong {
    font-weight: 600;
  }

  .Typewriter {
    @media (min-width: 768px) {
      display: inline;
      margin-left: 0.7rem;
    }
  }
`

const Preface = styled.div`
  @media (min-width: 768px) {
    display: inline;
  }
`

const Subheader = styled.div`
  font-size: 1.2rem;
  font-color: ${(props) => props.textColor};
  font-weight: 400;
  text-align: left;
  margin: 10px 0;
`

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.textColor};
  text-shadow: ${(props) =>
    props.textShadow ? '2px 2px rgba(0, 0, 0, 0.3)' : null};

  h1,
  h2,
  h3,
  h4 {
    color: ${(props) => props.textColor};
    text-shadow: ${(props) =>
      props.textShadow ? '2px 2px rgba(0, 0, 0, 0.3)' : null};
  }
`

const Container = styled.div`
  z-index: 10;
`

const ContentWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
  z-index: 10;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 0;
  margin-top: -100px;
  width: 100%;

  form,
  input,
  button {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`

const Col = styled.div`
  width: 50%;
  z-index: ${(props) => props.zindex};

  @media (max-width: 768px) {
    width: 100%;
  }
`

const LogoWrapper = styled.div`
  height: 200px;
  margin-bottom: 20px;
  text-align: left;
  display: inline-flex;

  .next-image {
    height: 200px !important;
    width: auto;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const Ul = styled.ul`
  list-style-type: none;
  margin: 20px 0 0 0;
  padding: 0;
`

const Li = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: green;
    margin-right: 5px;
    height: 16px;
    width: 16px;
  }
`
