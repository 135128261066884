import React from 'react'
import { FormWrapper } from '../../../../../theme/default/base'
import { BooleanInput } from '../../form-inputs/Boolean'
import { ColorPicker } from '../../form-inputs/ColorPicker'
import { HtmlInput } from '../../form-inputs/Html'
import { MediaInputPB } from '../../form-inputs/Media'
import { OpacityInput } from '../../form-inputs/Opacity'
import { StandardInput } from '../../form-inputs/Standard'
import { getImageUrl, hasImage } from '../../utils'

export const PillarsForm = ({
  bg_color = '#fff',
  text_color = 'black',
  text_shadow = false,
  title,
  subtitle,
  pillars,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <StandardInput
        value={title}
        name="title"
        label="Title"
        setCurrentComponent={setCurrentComponent}
      />
      <StandardInput
        value={subtitle}
        name="subtitle"
        label="Subtitle"
        setCurrentComponent={setCurrentComponent}
      />
      {pillars.map(({ url, title, description, image }, index) => {
        return (
          <React.Fragment key={index}>
            <h3>{`Pillar ${index + 1}`}</h3>
            <MediaInputPB
              label={`Pillar ${index + 1} image`}
              handleChange={(e) => {
                if (e === null) {
                  setCurrentComponent((prev) => {
                    const temp = { ...prev }
                    temp.props.pillars[index].image.media_center = null
                    temp.props.pillars[index].image.urls = []
                    return temp
                  })
                } else {
                  setCurrentComponent((prev) => {
                    const temp = { ...prev }
                    if (temp?.props?.pillars[index]?.image) {
                      temp.props.pillars[index].image.media_center = e
                    } else {
                      temp.props.pillars[index].image = {
                        media_center: e,
                        opacity: 1,
                        urls: []
                      }
                    }
                    return temp
                  })
                }
              }}
              image={image}
            />
            <StandardInput
              value={title}
              label="Title"
              onChange={(e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props.pillars[index].title = e.target.value
                  return temp
                })
              }}
              setCurrentComponent={setCurrentComponent}
            />
            <StandardInput
              value={description}
              type="textarea"
              label="Description"
              onChange={(e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props.pillars[index].description = e.target.value
                  return temp
                })
              }}
              setCurrentComponent={setCurrentComponent}
            />
            <StandardInput
              value={url}
              label="Url"
              onChange={(e) => {
                setCurrentComponent((prev) => {
                  const temp = { ...prev }
                  temp.props.pillars[index].url = e.target.value
                  return temp
                })
              }}
              setCurrentComponent={setCurrentComponent}
            />
          </React.Fragment>
        )
      })}
    </FormWrapper>
  )
}
