import { FormWrapper } from '../../../../../theme/default/base'
import { BooleanInput } from '../../form-inputs/Boolean'
import { ColorPicker } from '../../form-inputs/ColorPicker'
import { HtmlInput } from '../../form-inputs/Html'
import { MediaInputPB } from '../../form-inputs/Media'
import { OpacityInput } from '../../form-inputs/Opacity'

export const FeatureListForm = ({
  title,
  titleColor,
  titleBackgroundColor,
  textColor,
  backgroundColor,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  feature7,
  feature8,
  feature9,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <ColorPicker
        name={'titleColor'}
        label="Title Color"
        value={titleColor}
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        name={'titleBackgroundColor'}
        label="Title Background Color"
        value={titleBackgroundColor}
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        name={'textColor'}
        label="Text Color"
        value={textColor}
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        name={'backgroundColor'}
        label="Background Color"
        value={backgroundColor}
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={title}
        name="title"
        label="Title"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature1}
        name="feature1"
        label="Feature 1"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature2}
        name="feature2"
        label="Feature 2"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature3}
        name="feature3"
        label="Feature 3"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature4}
        name="feature4"
        label="Feature 4"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature5}
        name="feature5"
        label="Feature 5"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature6}
        name="feature6"
        label="Feature 6"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature7}
        name="feature7"
        label="Feature 7"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature8}
        name="feature8"
        label="Feature 8"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={feature9}
        name="feature9"
        label="Feature 9"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}
